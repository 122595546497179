.block-textarea {
  position: absolute;
  background: transparent;
  text-align: center;
  z-index: 990;
  font-family: ABCFavorit;
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 400;
  padding: 0px;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  line-break: auto;
  border: none;
}

.flow-block-textarea {
  height: auto;
}

.block-delete {
  z-index: 990;
  position: absolute;
  background: #faf5e5;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
}

.block-delete::after {
  background: url('./delete.svg');
  position: absolute;
  top: 7px;
  left: 7px;
  content: '';
  display: block;
  height: 15px;
  width: 15px;
}

.block-background {
  z-index: 880;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.column-textarea {
  margin-top: -7px;
  position: absolute;
  background: #f8f8f9;
  text-align: center;
  z-index: 790;
  font-family: ABCFavorit;
  line-height: 19px;
  font-size: 16px;
  font-weight: 500;
  height: 25px;
  padding: 3px 5px 0px 5px;
  box-sizing: border-box;

  border: none;
  overflow: hidden;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  border: 0.5px solid #00a1d3;
}

.column-background {
  z-index: 800;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
