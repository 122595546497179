/* https://piccalil.li/blog/a-modern-css-reset */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  text-rendering: optimizeSpeed;
  font-family: GTPressura, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html:focus-within {
  scroll-behavior: smooth;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
textarea,
button,
select {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a:visited {
  text-decoration: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.patient-menu .cypress-keystakeholder-create {
  width: 100%;
  margin: 5px 0px;
}

.outcome-dropzone__empty-state-include {
  height: 75px !important;
}
.outcome-dropzone__empty-state-exclude {
  height: 200px !important;
}
.items-saving .outcome-card__wrapper {
  opacity: .6;
  cursor: not-allowed;
}


:root {
  --gradient-purplepink: #FF00C8;
}